#table-container{
    margin-top: 25px;
}

.token{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.token h6{
    margin: 0;
}