.card {
  background: var(--surface-card);
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.p-column-header-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-column-filter-clear-button {
  display: none;
}

.p-dropdown-clear-icon,
.p-multiselect-clear-icon {
  top: 31% !important;
}

.p-multiselect-clear-icon {
  right: 2.5rem !important;
}

td{
    text-align: center;
}

.p-dropdown-items, .p-multiselect-items{
    padding: 0;
    margin-top: 15px;
}

.p-multiselect-checkbox{
    margin-right: 10px;
}