.rdt_Pagination {
    justify-content: center !important;
    border-radius: 0 0 15px 15px;
  }
  
  #table-container header,
  #control-panel-body header {
    border-radius: 15px 15px 0 0;
    background-color: none;
  }
  
  .rdt_TableCol,
  .rdt_TableCell {
    justify-content: center;
  }
  
  .noData p {
    margin-top: 16px;
  }
  
  .table-search {
    width: 150px !important;
  }
  
  .table-header-container {
    margin-top: 16px;
    width: 100%;
  }
  
  .admin-button:hover,
  .balance:hover,
  .send-cost:hover {
    cursor: pointer;
  }

  