*{
    margin: 0;
    box-sizing: border-box;
}

#content {
    margin-left: 320px !important;
  }

  #toggleTheme{
    position: absolute;
    right: 15px;
    top: 15px;
  }