#body-login {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  margin: 0;
  overflow: hidden;
}

.img {
  flex: 3;
  padding: 20px;
  text-align: center;
}

.content-login {
  padding: 20px;
  border-radius: 8px 0 0 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;
}

.form {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form input[type="text"],
.form input[type="password"] {
  padding: 8px;
  font-size: 16px;
  border: none;
  width: 250px;
  border-bottom: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
}

input:focus {
  outline: none;
  border-bottom: 1px solid black;
}

.form .submit {
  margin-top: 20px;
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.form .submit:hover {
  background-color: #0056b3;
}

.imgLogo {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.error-message {
  color: #ff0000;
  background-color: #ffe6e6;
  padding: 10px;
  border: 1px solid #ff0000;
  border-radius: 5px;
  margin-bottom: 15px;
  text-align: center;
}

.imgLogo img {
  width: 200px;
}

.img img {
  width: 500px;
}



