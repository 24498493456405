body {
  background-color: #181e26;
  color: #fff;
}

#sidebar,
.user-card,
.channel-card {
  background-color: #212529;
}

.user-card,
.channel-card {
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.49);
}

#sidebar {
  box-shadow: 10px 0px 5px 0px rgba(0, 0, 0, 0.08);
}

#content {
  margin: 40px;
}

#sidebar a {
  color: #fff;
}

.user-card button,
.channel-card button {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

#whatsapp {
  color: #128c7e;
}

.dropdown-menu {
  background-color: #393e46;
}

.dropdown-menu li a {
  color: #fff;
}

.dropdown-menu li div {
  color: #fff;
}

.content-login {
  background-color: #393e46;
}

#password::placeholder,
#login::placeholder {
  color: #fff;
  opacity: 1;
}

#login,
#password {
  background-color: #393e46;
  border-bottom: 1px solid white;
  color: white;
}

#body-login {
  background-color: #212529;
}

.rdt_TableHeadRow,
.rdt_TableHead,
.rdt_Table,
.rdt_TableRow,
.rdt_Pagination,
#table-container header,
#control-panel-body header {
  background-color: #212529 !important;
  color: #fff !important;
}

.rdt_Pagination button svg {
  fill: #fff;
}

.noData {
  display: flex;
  justify-content: center;
  background-color: #212529;
  color: #fff;
  width: 100%;
}

.data-input {
  background-color: #393e46 !important;
}

.data-card {
  background-color: #212529;
}

.passButton {
  color: white !important;
}

#change-password-button {
  bottom: 80px;
  background-color: #fbc107;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 15px;
  color: #fff;
  border-radius: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#exit-button {
  bottom: 15px;
  background-color: red;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 15px;
  color: #fff;
  border-radius: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#change-password-button:hover{
  cursor: pointer;
}

#exit-button:hover{
  cursor: pointer;
}