#content-header {
  margin-bottom: 40px;
}

#user-wraper {
  height: 100vh;
  overflow-y: auto;
}

#create-user {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: #198754;
  right: 15px;
  bottom: 15px;
  font-size: 30px;
  border-radius: 75%;
  color: white;
}

#create-user:hover {
  cursor: pointer;
}

#stk-telefone-container {
  border: 0px;
  background-color: #181e26;
  position: fixed;
  z-index: 9999;
  right: 0;
  bottom: -0px;
  display: flex;
  flex-direction: column;
  height: 32px;
  width: 337px;
}

.data-card {
  border-radius: 15px;
  padding: 40px;
  padding-bottom: 45px;
  width: 30%;
  -webkit-box-shadow: 0px 0px 17px 8px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 17px 8px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 17px 8px rgba(0, 0, 0, 0.3);
}

h1 {
  margin-bottom: 15px;
  margin-top: 0;
  padding-top: 0;
}

.name-input {
  margin-bottom: 10px;
}

.agent-call-reload{
  margin-left: 10px;
  width: 30px;
  height: 30px;
  background-color: #2B5ED7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.agent-call-reload:hover{
  cursor: pointer;
}

.agent-header{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.agent-header h1{
  margin: 0;
}